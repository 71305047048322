<template>
  <div
    ref="containerRef"
    :class="
      cn(
        'mx-14 flex flex-1 items-center justify-between px-1 md:flex-col md:items-start md:gap-8 lg:mx-0',
        props.class
      )
    "
  >
    <i18n-t class="text-2.5xl/7 w-full pe-10 font-semibold md:hidden" tag="span" keypath="headline">
      <template #long>
        <LandingHomeChip color="blue">{{ t("long10")?.toLowerCase() }}</LandingHomeChip>
      </template>

      <template #short>
        <LandingHomeChip color="purple">{{ t("short10")?.toLowerCase() }}</LandingHomeChip>
      </template>

      <template #neutral>
        <LandingHomeChip color="orange"> {{ t("longShort20")?.toLowerCase() }}? </LandingHomeChip>
      </template>
    </i18n-t>

    <i18n-t
      class="text-2.5xl/7 hidden w-full pe-10 font-semibold md:block md:text-2xl/7"
      tag="div"
      keypath="mobile_headline"
    >
      <template #type>
        <LandingHomeChip
          v-if="currentPortfolioChip"
          :color="currentPortfolioChip.color"
          :text="currentPortfolioChip.text?.toLowerCase()"
        />
      </template>
    </i18n-t>

    <div :class="cn('grid-cols-auto-fill md:mb-s relative flex w-full justify-end gap-0')">
      <UiTransition name="fade" mode="out-in">
        <LandingHomeMovingCardsList
          :list="types"
          ref="movingCardsListRef"
          :key="query.isFetched.value ? 'types' : 'skeletons'"
        >
          <template #item="{ item, className }">
            <StocksIndexPortfolioCard
              :item="item!"
              variant="small"
              :class-name="
                cn(
                  className,
                  'shadow-below reversed-shadow-below -ms-24 size-56 flex-1 first:ms-0 md:-ms-[65%]'
                )
              "
              short-description
            />
          </template>
        </LandingHomeMovingCardsList>
      </UiTransition>
      <StocksDepotPartialsReturnsDateInformation
        :classes="{
          root: 'absolute -bottom-3 start-0 z-10 translate-y-full transform md:-bottom-6',
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LandingHomeMovingCardsList } from "#components"
import type { ComponentExposed } from "vue-component-type-helpers"

import { type MappedStocksPortfolioType, PortfolioType } from "@finq/stocks/types/stocks-portfolios"
import type { UiCardClasses } from "@finq/ui/components/ui/Card.vue"

const props = defineProps<{
  class?: ClassValue
  classes?: { root?: ClassValue; card?: Partial<UiCardClasses> }
}>()

const { isIntersected, containerRef } = useIsIntersected()
const { query, types } = useStockIndexServices({ enabled: isIntersected })

const { t } = useI18n({ useScope: "local" })

const movingCardsListRef =
  ref<ComponentExposed<typeof LandingHomeMovingCardsList<MappedStocksPortfolioType>>>()

const reorderedListLastItem = computed(() => {
  return movingCardsListRef.value?.reorderedList?.at(-1)
})

const chipColors = {
  [PortfolioType.Long]: "blue",
  [PortfolioType.Short]: "purple",
  [PortfolioType.LongShort]: "orange",
  [PortfolioType.Snp]: "orange",
} as const

const currentPortfolioChip = computed(() => {
  if (!reorderedListLastItem?.value) return null

  return {
    text: t(reorderedListLastItem?.value.id),
    color: chipColors[reorderedListLastItem.value.id],
  }
})

const { counter, resume } = useInterval(6000, { controls: true, immediate: false })

watch(
  () => counter.value,
  () => {
    movingCardsListRef.value?.reorderLastToFirst()
  }
)

watchEffect(() => {
  if (query.isFetched.value) resume()
})
</script>

<i18n lang="json">
{
  "en": {
    "headline": "Choose {long} or {short} or maybe {neutral}",
    "mobile_headline": "Choose {type}",
    "description": "AI-based stocks portfolios with three distinct strategies, built to outperform the S&P",
    "plans_count": "{count} plans",
    "long10": "Long",
    "short10": "Short",
    "longShort20": "Neutral",
    "section_title": "AI-based model portfolios designed to beat the S&P 500",
    "returns_from": "Returns from {fromDate} to {toDate}"
  },
  "he": {
    "headline": "אסטרטגיית {long} או {short} או אולי {neutral}",
    "mobile_headline": "אסטרטגיית {type}",
    "description": "תיקי מניות מבוססי בינה מלאכותית עם שלוש אסטרטגיות ייחודיות שנבנו כדי לייצר תשואה גבוהה ממדד ה-S&P",
    "plans_count": "{count} תוכניות",
    "long10": "לונג",
    "short10": "שורט",
    "longShort20": "נייטרלית",
    "section_title": "תיקי מודל מניות מבוססי AI שנבנו כדי לנצח את ה-S&P 500",
    "returns_from": "תשואות מתאריך {fromDate} עד תאריך {toDate}"
  }
}
</i18n>
