<template>
  <div :class="cn('service-card flex flex-col overflow-hidden rounded-lg bg-neutral-100', props.class)">
    <div class="service-card-gradient pointer-events-none absolute inset-x-0 top-0 z-0" role="image" />

    <UiTransition mode="out-in" name="scrollX">
      <div :class="cn('z-1 flex flex-1 flex-col p-5')" :key="service.id">
        <UiInfo
          :title="service.label"
          :subtitle="t('plans_count', { count: Object.values(service.plans).length })"
          :classes="{
            root: 'md:mb-10',
            wrapper: 'gap-3',
            titlesWrapper: 'gap-0',
            title: 'text-lg/5 font-semibold',
            subtitle: 'text-sm/5 font-light text-black/50',
          }"
        >
          <template #image>
            <QLogoIcon :product-id="service.id!" outline="none" shadow="none" />
          </template>
        </UiInfo>

        <LandingHomeSectionServicesContent :id="service.id" />

        <div class="mt-auto flex items-end justify-between md:mt-8 md:flex-col md:items-start md:gap-8">
          <UiTransition mode="out-in">
            <UiHTMLRenderer
              class="description-max-width max-w-400 text-base/6 font-medium text-black/50 md:max-w-full"
              :key="service.id"
              :content="t(`services.descriptions.${service.id}`)"
            />
          </UiTransition>

          <!-- Notify me button -->
          <UiButton
            class="border-primary w-fit max-w-48 disabled:border-black/30 md:mx-auto md:w-full"
            v-if="isAnUpcomingPlan"
            variant="outline"
            :id="genClickId('index', 'service', 'notifyme', service.id)"
            @click="handleNotifyMe"
            :disabled="isUserWaitingListed"
          >
            {{ !isUserWaitingListed ? pt("buttons.notify_me") : pt("buttons.notify_me_selected") }}
          </UiButton>

          <UiButton
            class="max-w-400 w-fit md:mx-auto md:w-full"
            v-else
            :id="genClickId('index', 'service', 'cta', service.id)"
            :to="service.path"
          >
            {{ t(`services.cta.${service.id}`) }}
          </UiButton>
        </div>
      </div>
    </UiTransition>
  </div>
</template>

<script setup lang="ts">
import { PricingPlanTypes, PricingProductEnum } from "@finq/pricing/types"

const user = useUser()
interface SelectedServiceProps {
  service: MappedProduct[PricingProductEnum]
  class?: ClassValue
}

const props = defineProps<SelectedServiceProps>()
const notifyMe = useNotifyMe()
const login = useLogin()
const { t } = useI18n({ useScope: "local" })
const { t: pt } = useI18n({ useScope: "parent" })

const serviceId = computed(() => props.service.id)
const { isAnUpcomingPlan, plan, isUserWaitingListed } = usePricingPlanHelperById(
  serviceId,
  PricingPlanTypes.PlanCode.DIY
)

function handleNotifyMe() {
  if (isUserWaitingListed.value) return
  else if (user.isAuthenticated.value) {
    notifyMe.open(plan.value)
  } else {
    login.open({ success: () => notifyMe.open(plan.value) }, "signup")
  }
}
</script>

<style lang="scss" scoped>
.service-card {
  min-height: 28.125rem; // 400px
  position: relative;
  background: rgba(0, 0, 0, 0.04);

  &-gradient {
    min-height: inherit;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fafafa 70%),
      repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.07) 0px, rgba(0, 0, 0, 0.04) 150px);
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "notify_me": "Notify me",
    "plans_count": "{count} plans",
    "services": {
      "descriptions": {
        "PRODUCT_STOCK": "AI-based stocks portfolios with three distinct strategies, built to outperform the S&P",
        "PRODUCT_FUND": "AI-based funds portfolios, tailored for every individual and built to outperform the benchmarks",
        "PRODUCT_PENSION": "A complete AI-guided and customized pension solution based on ranking the entire pension savings world"
      },
      "cta": {
        "PRODUCT_STOCK": "Explore stocks",
        "PRODUCT_FUND": "Explore funds",
        "PRODUCT_PENSION": "Explore pensions"
      }
    }
  },
  "he": {
    "notify_me": "הודיעו לי",
    "plans_count": "{count} תוכניות",
    "services": {
      "descriptions": {
        "PRODUCT_STOCK": "תיקי מניות מבוססי בינה מלאכותית עם שלוש אסטרטגיות ייחודיות שנבנו כדי לייצר תשואה גבוהה ממדד ה-S&P",
        "PRODUCT_FUND": "תיקי קרנות מבוססי בינה מלאכותית מותאמים ספציפית לצרכים שלך, הנבנו כדי לייצר תשואה גבוהה ממדדי השוק המובילים בעולם",
        "PRODUCT_PENSION": "פתרון פנסיוני מלא מבוסס AI ומותאם אישית המסתמך\n על דירוג של כל עולם החסכונות הפנסיונים"
      },
      "cta": {
        "PRODUCT_STOCK": "גלה/י עוד",
        "PRODUCT_FUND": "גלה/י עוד",
        "PRODUCT_PENSION": "גלה/י עוד"
      }
    }
  }
}
</i18n>
