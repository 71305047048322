<template>
  <section :class="cn('container flex flex-col', props.class)">
    <FrequentlyAskedQuestions :classes="{ root: 'w-full' }" :list="faqList" />
  </section>
</template>

<script setup lang="ts">
import type { FAQSectionProps } from "@finq/ui/components/FrequentlyAskedQuestions.vue"

const props = defineProps<{
  class?: ClassValue
}>()

const { tmsafe } = useI18nUtils({ useScope: "local" })
const faqList = computed(() => tmsafe("faq_list") as FAQSectionProps["list"])
</script>

<style lang="scss" scoped></style>

<i18n lang="json">
{
  "he": {
    "faq_list": [
      {
        "question": "במה שונה FINQ מפלטפורמות השקעה אחרות?",
        "answer": "FINQ משתמשת בבינה מלאכותית ובמודלים של Big Data על מנת להפוך את הצפת הנתונים הפיננסיים לתובנות פשוטות, אובייקטיביות, עדכניות ומעשיות. הפלטפורמה שלנו מייתרת את הצורך במתווכים פיננסיים מסורתיים, ומעבירה אליך את השליטה בעזרת ניתוח שוק על בסיס יומי המגובה בתובנות מדעיות."
      },
      {
        "question": "כיצד אוכל לקבל עדכונים מ-FINQ?",
        "answer": "הודעות האימייל שלנו מכילות את התובנות העדכניות ביותר של FINQ-AI. את/ה יכול גם לבקר באתר האינטרנט שלנו באופן קבוע כדי להתעדכן על בסיס יומי בכל פעולות התיקים והדירוגים שלנו."
      },
      {
        "question": "האם FINQ יכולה לתרום לי אם אני חדש/ה בעולם ההשקעות?",
        "answer": "בהחלט. בין אם את/ה טירון/ית או משקיע/ה ותיק/ה, FINQ מפשטת את עולם ההשקעות המורכב על ידי מתן תובנות ברורות ומעשיות. הפלטפורמה שלנו מציעה תיקים עם אסטרטגיות השקעה ייחודיות וכן דירוגים רציפים מבוססי מדע כדי לעזור לך לקבל החלטות השקעה בטוחות."
      },
      {
        "question": "באיזו תדירות מתעדכנים הדירוגים והתיקים של FINQ?",
        "answer": "דירוג המניות והתיקים שלנו מתעדכנים מדי יום. מערכת FINQFULL והתיקים הייחודיים שלנו כמו FINQFIRST ו-FINQLAST מספקים המלצות רציפות המבוססות על המגמות והתובנות העדכניות ביותר בשוק."
      },
      {
        "question": "איזה סוג של אסטרטגיות השקעה אני יכול לממש עם FINQ?",
        "answer": "FINQ מציעה מגוון אסטרטגיות השקעה המותאמות לכל רמת סיכון, ומספקת כלים המיועדים להתאים את ההשקעות שלך להעדפותיך הפיננסיות. הפלטפורמה שלנו מאפשרת זיהוי מניות עם ביצועים מובילים עם FINQFIRST, זיהוי הזדמנויות פוטנציאליות למכירה בחסר עם FINQLAST, או שמירה על גישה מאוזנת עם FINQNEUTRAL."
      }
    ]
  },
  "en": {
    "faq_list": [
      {
        "question": "What makes FINQ different from other investment platforms?",
        "answer": "FINQ stands out by leveraging AI and Big Data analytics to transform the overflow of financial data into unbiased, up-to-date, and actionable insights. Our platform eliminates the need for traditional financial intermediaries, putting you in control with continuous, science-backed market analyses."
      },
      {
        "question": "How can I get notified about FINQ’s updates?",
        "answer": "Our email notifications contain the latest insights from FINQ-AI. You can also visit our website regularly to catch up with continuous updates on our portfolios and ranking."
      },
      {
        "question": "Can FINQ help me if I’m new to investing?",
        "answer": "Absolutely. Whether you’re a novice or a seasoned investor, FINQ simplifies the complex world of investing by providing clear and actionable insights. Our platform offers uniquely strategic portfolios plus continuous, data-driven rankings to help you make confident investment decisions."
      },
      {
        "question": "How often are FINQ’s rankings and portfolios updated?",
        "answer": "Our stock rankings and portfolio are updated daily. The FINQFULL system and our specialized portfolios like FINQFIRST and FINQLAST provide a continuous flow of recommendations based on the latest market trends and insights."
      },
      {
        "question": "What kind of investment strategies can I pursue with FINQ?",
        "answer": "We offer a range of investment strategies tailored to different risk appetites and goals, ensuring you have the tools to align your investments with your financial preferences. Our platform allows you to pursue top-performing stocks with FINQFIRST, identify potential short-selling opportunities with FINQLAST, or maintain a balanced approach with FINQNEUTRAL."
      }
    ]
  }
}
</i18n>
